<template>
  <section class="about" style="background: #FFF; position: relative;">
    <v-img
      v-if="info.banners[0]"
      :src="info.banners[0].img"
      class="tnf-top-banner tnf-join-banner"
      >
      <h3>招商加盟</h3>
      <h4><div class="yellow-line"></div></h4>
      <h5 class=" hidden-sm-and-down">
        <div>与特诺发一起，共赢“智能门窗”蓝海市场，成为特诺发渠道伙伴，为千万企业和家庭开启智能家居新时代</div>
      </h5>
    </v-img>
    <v-container  grid-list-md>
      <v-layout wrap mt-6 mb-6>
        <v-flex xs12 sm12 md12 lg12>
          <template>
            <v-tabs
              class="join-submenu"
              v-model="tabSubMenu"
              light
              centered
            >
              <v-tab
                v-for="menu in subMenu"
                :key="menu.key"
              >
                <a :href="'#' + menu.key">{{menu.name}}</a>
              </v-tab>
            </v-tabs>
          </template>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 mb-4 mt-10>
          <h3 class="tnf-join-title" id="condition">加盟条件<span>Join Condition</span></h3>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12>
          <v-layout wrap>
            <v-flex xs12 sm12 md4 lg4 pr-md-12 pr-lg-12>
              <v-layout wrap class="join-condition">
                <v-flex xs12 sm12 md12 lg12 class="no-padding">
                  <v-img
                    class="tj-title"
                    src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/join_tj_bg.png"
                    contain
                  >
                    <h3>联合运营中心</h3>
                  </v-img>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 pa-6 mb-6>
                  <h4>店面要求：</h4>
                  <p>地级市以上区域拥有不少于3家城市旗舰店</p>
                  <h4>团队要求：</h4>
                  <p>拥有专业的销售、技术和安装团队</p>
                  <h4>保证金要求：</h4>
                  <p>保证金5万</p>
                  <div class="text-center join-btn"><v-btn outlined color="#0C65A5" width="120" href="/join#want">申请加入</v-btn></div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 lg4 pr-md-6 pr-lg-6 pl-md-6 pl-lg-6>
              <v-layout wrap class="join-condition">
                <v-flex xs12 sm12 md12 lg12 class="no-padding">
                  <v-img
                    class="tj-title"
                    src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/join_tj_bg.png"
                    contain
                  >
                    <h3>城市旗舰店</h3>
                  </v-img>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 pa-6 mb-6>
                  <h4>店面要求：</h4>
                  <p>本区域一类建材市场不少于100平方米</p>
                  <h4>团队要求：</h4>
                  <p>不少于3人的符合特诺发标准的专业团队</p>
                  <h4>保证金要求：</h4>
                  <p>保证金3万</p>
                  <div class="text-center join-btn"><v-btn outlined color="#0C65A5" width="120" href="/join#want">申请加入</v-btn></div>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 sm12 md4 lg4 pl-md-12 pl-lg-12>
              <v-layout wrap class="join-condition">
                <v-flex xs12 sm12 md12 lg12 class="no-padding">
                  <v-img
                    class="tj-title"
                    src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/join_tj_bg.png"
                    contain
                  >
                    <h3>品牌店中店</h3>
                  </v-img>
                </v-flex>
                <v-flex xs12 sm12 md12 lg12 pa-6 mb-6>
                  <h4>店面要求：</h4>
                  <p>类建材市场不少于20平方米</p>
                  <h4 class="hidden-sm-and-down">&nbsp;</h4>
                  <p class="hidden-sm-and-down">&nbsp;</p>
                  <h4 class="hidden-sm-and-down">&nbsp;</h4>
                  <p class="hidden-sm-and-down">&nbsp;</p>
                  <div class="text-center join-btn"><v-btn outlined color="#0C65A5" width="120" href="/join#want">申请加入</v-btn></div>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap mt-6 mb-12>
        <v-flex xs12 sm12 md12 lg12 mb-4 mt-10>
          <h3 class="tnf-join-title" id="process">加盟流程<span>Joining Process </span></h3>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12 pa-lg-12 pa-md-12>
          <v-img
            class="hidden-sm-and-down"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/join_lc_pc.png"
            contain
          ></v-img>
          <v-img
            class="hidden-lg-only hidden-md-only hidden-xl-only"
            src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/join_lc_mobile.png"
            contain
          ></v-img>
        </v-flex>
      </v-layout>
    </v-container>
    <v-layout pa-md-6 pa-lg-6 pa-sm-1 pa-xs-1>
      <v-img
        :max-height="maxSupportBg"
        src="https://ctk-website.oss-cn-shanghai.aliyuncs.com/tryba/join_suport_bg.jpg"
      >
        <v-container pt-12 pb-12>
          <v-layout wrap>
              <v-flex xs12 sm12 md12 lg12>
                <h3 class="tnf-join-title tnf-join-title2" style="color:#FFF;" id="support">盟商支持<span>Franchisees Support</span></h3>
              </v-flex>
              <v-flex xs12 sm12 md3 lg3 class="support-content">
                <h4>丰富的市场推广活动</h4>
                <v-layout wrap>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 展会</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 线上网络营销宣传</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 线下推广活动</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md3 lg3 class="support-content">
                <h4>统一的VI/SI系统</h4>
                <v-layout wrap>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 名片</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 工作牌</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 汽车外观</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 海报等</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md3 lg3 class="support-content">
                <h4>统一的软件中心</h4>
                <v-layout wrap>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 客户管理CRM</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 下单系统</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 标准技术资料</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 订单查询</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md3 lg3 class="support-content">
                <h4>店面管理服务及培训</h4>
                <v-layout wrap>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 店面管理培训</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 产品培训</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 商务培训</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 技术培训</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 安装、售后培训</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md3 lg3 class="support-content">
                <h4>统一的广宣物料</h4>
                <v-layout wrap>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 办公用品</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 礼品</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 样角展示箱</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md3 lg3 class="support-content">
                <h4>移动营销策略及媒介投放</h4>
                <v-layout wrap>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 品牌建设&运营</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 品牌信息化建设</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 线上广告运营</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 后端转化跟单数据化</v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 sm12 md3 lg3 class="support-content">
                <h4>统一的硬件中心</h4>
                <v-layout wrap>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 标准展厅</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 标准配置</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 展示系统</v-flex>
                  <v-flex xs6 sm6 md12 lg12 class="support-li">• 布置规范</v-flex>
                </v-layout>
              </v-flex>
          </v-layout>
        </v-container>
      </v-img>
    </v-layout>
    <v-container>
      <v-layout wrap mt-6 mb-12>
        <v-flex xs12 sm12 md12 lg12 mb-4 mt-10>
          <h3 class="tnf-join-title" id="want">我要加盟<span>Want To Join</span></h3>
        </v-flex>
        <v-flex xs12 sm12 md12 lg12>
          <v-layout wrap class="join-form">
            <v-flex xs12 sm12 md12 lg12 pl-4 pr-4>
              <p class="join-form-p">感谢您填写在线信息。请保证您的电话处于正常开机状态，我们会有专员第一时间和您取得联系。</p>
            </v-flex>
            <v-flex xs12 sm12 md6 lg6 xl5>
              <template>
                <v-form
                  v-model="messageForm"
                  ref="messageForm"
                  style="width:100%"
                >
                  <v-container
                    fluid
                    grid-list-sm
                    mb-12
                    class="text-left"
                  >
                    <v-layout wrap>
                      <v-flex xs12 sm12 md12 lg12>
                        <v-layout wrap>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-text-field
                              height="40"
                              outlined
                              name="name"
                              label="姓名"
                              :rules="[v => !!v || '请输入姓名']"
                              v-model="formData.name"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-text-field
                              height="40"
                              name="city"
                              outlined
                              label="现居住城市"
                              :rules="[v => !!v || '请输入现居住城市']"
                              v-model="formData.city"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-text-field
                              height="40"
                              name="join_city"
                              outlined
                              label="意向加盟城市"
                              :rules="[v => !!v || '请输入意向加盟城市']"
                              v-model="formData.join_city"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-text-field
                              height="40"
                              name="mobile"
                              outlined
                              label="手机号"
                              :rules="[
                                v => !!v || '请输入手机号',
                                v => /^[1][0-9]{10}$/.test(v) || '请输入正确的手机号']"
                              v-model="formData.mobile"
                            ></v-text-field>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-layout wrap>
                              <v-flex xs8 sm8 md8 lg8>
                                <v-text-field
                                  height="40"
                                  name="code"
                                  outlined
                                  label="验证码"
                                  :rules="[
                                    v => !!v || '请输入验证码',
                                    v => /(^[\-0-9][0-9]*(.[0-9]+)?)$/.test(v) || '验证码错误']"
                                  v-model="formData.code"
                                >
                                </v-text-field>
                              </v-flex>
                              <v-flex xs4 sm4 md4 lg4 pl-4>
                                <v-btn depressed :disabled="btnDisable" width="100%" height="40" id="send_code_btn" @click="sendCode">获取验证码</v-btn>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-textarea
                              name="content"
                              outlined
                              label="留言"
                              rows="6"
                              v-model="formData.content"
                            ></v-textarea>
                          </v-flex>
                          <v-flex xs12 sm12 md12 lg12>
                            <v-btn outlined color="#0C65A5" @click="submitForm" width="120">
                              提交
                            </v-btn>
                          </v-flex>
                        </v-layout>
                          </v-flex>
                        </v-layout>
                  </v-container>
                </v-form>
              </template>
              <template>
                <v-row justify="center">
                  <v-dialog v-model="dialog" persistent max-width="290">
                    <v-card>
                        <v-card-title class="headline">操作提示</v-card-title>
                      <v-card-text>{{returnMsg}}</v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary darken-1" text @click="dialog = false" >确定</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </section>
</template>
<script>
export default {
  data: () => ({
    pageId: 13,
    innerWidth: window.innerWidth,
    maxSupportBg: window.innerWidth > 960 ? 620 : 'auto',
    tabSubMenu: null,
    subMenu: [
      { key: 'condition', name: '加盟条件' },
      { key: 'process', name: '加盟流程' },
      { key: 'support', name: '盟商支持' },
      { key: 'want', name: '我要加盟' }
    ],
    messageForm: false,
    returnMsg: null,
    dialog: false,
    formData: {
      name: '',
      mobile: '',
      city: '',
      join_city: '',
      code: '',
      content: ''
    },
    btnDisable: false,
    itv: '',
    scd: 60
  }),
  created () {
  },
  mounted () {
    let hash = location.hash.slice(1)
    if (hash) {
      this.goAnchor(hash)
    }
  },
  watch: {
    '$store.getters.companyInfo': {
      handler: function (val, oldVal) {
        this.info.banners = val.banner[this.pageId]
      },
      deep: true
    }
  },
  computed: {
    info () {
      let banners = {}
      banners[this.pageId] = []
      if (this.$store.getters.companyInfo.banner) {
        banners = this.$store.getters.companyInfo.banner[this.pageId]
      }
      return {
        banners: banners
      }
    }
  },
  methods: {
    goAnchor (selector) {
      let el = document.getElementById(selector)
      if (el) {
        el.scrollIntoView(true)
      }
    },
    sendCode () {
      if (/^[1][0-9]{10}$/.test(this.formData.mobile)) {
        this.btnDisable = true
        document.getElementById('send_code_btn').innerText = '发送中...'
        var that = this
        this.https.get('join_code', { mobile: this.formData.mobile }).then(response => {
          if (response.code === 0) {
            that.itv = setInterval(function () {
              if (that.scd > 1) {
                that.scd -= 1
                document.getElementById('send_code_btn').innerText = '等待' + that.scd + '(s)'
              } else {
                that.btnDisable = false
                document.getElementById('send_code_btn').innerText = '发送验证码'
                that.scd = 60
                clearInterval(that.itv)
              }
            }, 1000)
          } else {
            that.btnDisable = false
            document.getElementById('send_code_btn').innerText = '发送验证码'
            that.scd = 60
            this.returnMsg = response.msg
            this.dialog = true
          }
        })
      } else {
        this.returnMsg = '请输入正确的手机号码'
        this.dialog = true
      }
    },
    submitForm () {
      this.$refs.messageForm.validate()
      if (this.messageForm === true) {
        this.https.get('online_join', this.formData).then(response => {
          if (response.code === 0) {
            this.$refs.messageForm.reset()
          }
          this.returnMsg = response.msg
          this.dialog = true
        })
      }
    }
  }
}
</script>
<style lang="css">
  @import '../css/join.css';
</style>
